import styled from "styled-components";
import { ButtonContained, FontStyles } from "../../components";

import { fonts, Spacing } from "../../config";

const ImageBG = styled.div(({ theme }) => {
  const { palette: colors } = theme;

  return {
    background: "linear-gradient(#02749A, #1A8FB6)",
    display: "flex",
    width: "100%",
    height: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "clamp(20px, 6%, 46px)",
    position: "relative",
    overflow: "hidden",
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    position: "relative",

    justifyContent: "flex-start",
    flexDirection: "column",
    padding: "clamp(16px, 6%, 32px)",
    overflowX: "auto",
    textAlign: "left",
    backgroundColor: colors.background.paper,
    borderRadius: spacing(1.1),

    width: "100%",

    height: "100%",
  };
});

const Header = styled.div(() => {
  return {
    width: "fit-content",
    marginBottom: Spacing(5),
    display: "flex",
    alignSelf: "center",
    justifyContent: "flex-start",
  };
});

const Title = styled.p(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    color: colors.text.secondary,
    margin: 0,
    textAlign: "left",
    ...FontStyles.semibold16,
    textTransform: "uppercase",
  };
});

const Subtitle = styled.p(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    color: colors.grayText,
    ...FontStyles.medium16,
    margin: 0,
    paddingBottom: Spacing(3),
  };
});

const CTGLogo = styled.img(() => {
  return {
    width: Spacing(17),
    height: "auto",
  };
});

const ForgotPasswordButton = styled.p(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    textAlign: "right",
    ...FontStyles.medium14,
    color: colors.blue,
    textDecoration: "underline",
    marginBottom: Spacing(4),
    cursor: "pointer",
  };
});

const Version = styled.div(() => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

const ButtonContainer = styled.div(() => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  };
});

const P = styled.p(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.light16,
    color: colors.text.secondary,
    margin: 0,
    marginLeft: spacing(2),
    padding: 0,
  };
});

const InputImageModal = styled.div(({ theme }) => {
  return {
    padding: 0,
    display: "flex",
    justifyContent: "flex-start",
    width: "100vw",
    maxWidth: "100%",
    cursor: "pointer",
  };
});

const InputImageContainer = styled.div(({ theme, error }) => {
  const { breakpoints } = theme;
  return {
    ...FontStyles.medium14,
    padding: theme.spacing(1.25),
    paddingInline: 0,

    textOverflow: "ellipsis",
    color: error ? "red" : "black",
    backgroundColor: "transparent",

    borderBottom: "1px solid black",

    [breakpoints.down("sm")]: {
      fontSize: Spacing(1.5),
    },
  };
});

const LabelImage = styled.p(({ theme, $withError, $color }) => {
  const { palette: colors, breakpoints } = theme;
  return {
    ...FontStyles.semibold12,
    textTransform: "uppercase",
    padding: 0,
    margin: 0,
    color: $withError
      ? colors.error.main
      : $color === 2
      ? colors.text.primary
      : $color === 1
      ? colors.orange
      : "black",
    transition: ".2s",
    pointerEvents: "none",
    alignItems: "center",
    display: "flex",
    overflow: "hidden",
    [breakpoints.down("sm")]: {
      fontSize: Spacing(1.25),
    },
  };
});

const CustomButton = styled(ButtonContained)(
  ({ theme, bg, color, $size, $padding, $width, noMargin }) => {
    return {
      "&&.MuiButton-root": {
        padding: theme.spacing(0.8),
        minWidth: "0px",
        width: theme.spacing(3.2),
        height: theme.spacing(3.2),
        fontSize: theme.spacing(2),
        color: "black",
        backgroundColor: "transparent",
        margin: 0,
        marginLeft: theme.spacing(0.5),
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "transparent",
        },
        "& .MuiSvgIcon-root": {
          fontSize: theme.spacing(2),
          margin: 0,
        },
        "& .MuiButton-startIcon": {
          marginRight: theme.spacing(2),
        },
      },
    };
  }
);

const Styles = {
  Content,
  Title,
  Subtitle,
  Header,
  CTGLogo,
  ImageBG,
  ForgotPasswordButton,
  Version,
  ButtonContainer,
  P,
  LabelImage,
  InputImageContainer,
  InputImageModal,
  CustomButton,
};

export default Styles;
