import React, { ReactElement } from "react";
import Styles from "../styles/Styles";
import MenuIcon from "@material-ui/icons/Menu";

type CustomHeaderProps = {
  openMenu: () => void;
};

function Header({ openMenu }: CustomHeaderProps) {
  return (
    <Styles.Container id="header-menu">
      <Styles.FirstContentGroup>
        <Styles.Rectangle onClick={openMenu}>
          <Styles.IconMenu />
        </Styles.Rectangle>
        {/* {userInfos && (
          <NavigationInfos pathname={pathname} userInfos={userInfos} />
        )} */}
      </Styles.FirstContentGroup>
      <Styles.UserContentGroup>
        {/* <UserInfos userInfos={userInfos} /> */}
      </Styles.UserContentGroup>
    </Styles.Container>
  );
}

export default Header;
