import React from "react";
// import { navigate } from "../../../navigation/navigate";
import Styles from "../styles/Styles";
import { customModal } from "../../../components/modals/utils";
import { pagesConfig } from "../../../config";

type CustomMenuGroupProps = {
  item: any;
  firstItem: boolean;
  headerName: string;
  pathname: string;
};

function MenuGroupWithPagesComponent({
  item,
  firstItem,
  headerName,
  pathname,
}: CustomMenuGroupProps) {
  const classes = Styles.useStyles();

  function handleLogout() {
    customModal.setInfos(
      "leave",
      ["confirm"],
      {
        label: "logout",
        onClick: () => {
          customModal.close();

          // globalLogout();
        },
      },
      {
        label: "back",
        onClick: () => {
          customModal.close();
        },
      },
      null,
      null,
      null
    );
  }

  const goto = (path: string, title: string) => {
    let link;
    if (!isDisabled(path) === false) {
      if (title === "Sair") {
        handleLogout();
      }
      // else {
      //   navigate(path);
      // }
    } else {
      link = null;
    }
    return link;
  };

  function isDisabled(path: string) {
    let a = false;
    pagesConfig.logged.map((item) => {
      return item.pages.map((element) => {
        if (path === element.path) {
          if (element.working !== (undefined || null)) {
            a = element.working;
          } else {
            a = false;
          }
        }
        return element;
      });
    });
    return a;
  }

  return (
    <Styles.Group $firstOne={firstItem}>
      <Styles.GroupTitleContainer>
        <Styles.GroupNameContainer>
          <Styles.GroupTitle>{headerName}</Styles.GroupTitle>
        </Styles.GroupNameContainer>
      </Styles.GroupTitleContainer>
      <Styles.SubGroup>
        {item.map((page: any, pageIndex: number) => {
          return (
            <Styles.PageLine
              key={pageIndex + page?.name}
              $currentPage={page.path === pathname}
            >
              <Styles.Page
                $buttonContained={false}
                $currentPage={page.path === pathname}
                $disabled={!isDisabled(page.path)}
                key={page.name + pageIndex}
                onClick={() => goto(page.path, page.title)}
              >
                <Styles.PageContent $currentPage={page.path === pathname}>
                  {page.icon ? (
                    <img alt="" src={page.icon} className={classes.icon} />
                  ) : null}
                  {page.title}
                </Styles.PageContent>
              </Styles.Page>
            </Styles.PageLine>
          );
        })}
      </Styles.SubGroup>
    </Styles.Group>
  );
}

export default MenuGroupWithPagesComponent;
