export const prefix = "";

export const paths = {
  login: prefix + "/",
  home: prefix + "/",
  registry: prefix + "/cadastro",
  forgetPassword: prefix + "/esqueceu_a_senha",

  profile: prefix + "/perfil",
  geral: prefix + "/geral",
  general: prefix + "/general",
  detail: prefix + "/detail",
  system: prefix + "/system",
};
