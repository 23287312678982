import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ptBr from "../../config/texts/pt-br";
import {
  ButtonContained,
  ButtonText,
  CustomText,
  Input,
  InputEmail,
  InputPassword,
} from "../../components/index";
import Styles from "./styles";
import { FormFull } from "form-full";
import { validations } from "../../utils";
import { hooks, SessionStorage } from "../../utils";
import { StorageContext } from "../../contexts/StorageContext";

import { api } from "../../services";
import { paths } from "../../navigation/navigate";
import { text } from "stream/consumers";
import { Grid } from "@material-ui/core";
import { fonts, Spacing, SVG } from "../../config";
import ImageInput from "./components/ImageInput";
import SocialNetwork from "./components/SocialNetwork";
import { customModal } from "../../components/modals/utils";
import TermsModal from "./components/TermsModal";
import alerts from "../../utils/alerts";

const termsString = `
**TERMOS DE USO E POLÍTICA DE PRIVACIDADE**\n
AO USUÁRIO MÉDICO(A) PROFISSIONAL DO OKIHEALTH\n
**1.LOREM IPSUM**\n
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vel lacus ultrices, bibendum odio quis, pellentesque justo. Maecenas interdum hendrerit finibus. In tempor id neque eu lobortis. Nam sed fermentum libero, ut euismod nunc. Fusce suscipit in est in commodo. Quisque orci arcu, egestas quis nisi sed, sodales efficitur dolor. Etiam malesuada nec tellus nec blandit. Proin vel risus quis erat pellentesque efficitur id quis odio. Quisque congue risus et velit gravida posuere. In egestas vel augue posuere tristique. Proin nec augue non ex convallis sagittis id ac quam. Cras eleifend massa egestas, tincidunt augue sit amet, elementum nisi.
\n**2.DOLOR SIT AMET**\n
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vel lacus ultrices, bibendum odio quis, pellentesque justo. Maecenas interdum hendrerit finibus. In tempor id neque eu lobortis. Nam sed fermentum libero, ut euismod nunc. Fusce suscipit in est in commodo. Quisque orci arcu, egestas quis nisi sed, sodales efficitur dolor. Etiam malesuada nec tellus nec blandit. Proin vel risus quis erat pellentesque efficitur id quis odio. Quisque congue risus et velit gravida posuere. In egestas vel augue posuere tristique. Proin nec augue non ex convallis sagittis id ac quam. Cras eleifend massa egestas, tincidunt augue sit amet, elementum nisi.
\n**3.LOREM IPSUM**\n
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vel lacus ultrices, bibendum odio quis, pellentesque justo. Maecenas interdum hendrerit finibus. In tempor id neque eu lobortis. Nam sed fermentum libero, ut euismod nunc. Fusce suscipit in est in commodo. Quisque orci arcu, egestas quis nisi sed, sodales efficitur dolor. Etiam malesuada nec tellus nec blandit. Proin vel risus quis erat pellentesque efficitur id quis odio. Quisque congue risus et velit gravida posuere. In egestas vel augue posuere tristique. Proin nec augue non ex convallis sagittis id ac quam. Cras eleifend massa egestas, tincidunt augue sit amet, elementum nisi.
`;

function Cadastro() {
  const texts = ptBr.cadastro;

  const navigate = useNavigate();
  const { loading, call } = hooks.useRequest();

  const [mainImg, setMainImg] = useState<any>(null);
  const [errorImg, setErrorImg] = React.useState<string | null>(null);

  const [socialLinks, setSocialLinks] = useState<any>(null);
  const [errorLinks, setErrorLinks] = React.useState<string | null>(null);

  const [formRef, setFormRef] = React.useState<any>();
  const Submit = async (data) => {
    const submitDaat = {
      username: data?.nickname,
      password: data?.password,
      email: data?.email,
      phone: data?.phone,
      fullName: data?.name,
      acceptTerms: true,
      biography: data?.bio,
      socialMedias: socialLinks.map((item) => {
        return { value: item.value, type: item.key };
      }),
      documentImage: mainImg?.src,
    };

    customModal.setInfos(
      "Termos de Uso",
      [],
      null,
      null,
      <TermsModal
        terms={termsString}
        onClick={() => {
          call(null, api.postNewUser(submitDaat), (response) => {
            if (response.ok) {
              customModal.close();
              alerts.alertSuccess("Usuario registrado com sucesso!");
              navigate(paths.login);
            }
          });
        }}
      />,
      false,
      true
    );
  };

  const getSubmitData = async () => {
    if (formRef) {
      let hasError: any = null;

      if (mainImg === null) {
        setErrorImg("Selecione uma imagem");
        hasError = true;
      }

      let dataForm = await formRef?.testErrorsAndReturnData();

      if (dataForm.hasError !== true && !hasError) {
        Submit(dataForm?.data);
      }
    }
  };

  console.log(socialLinks);
  return (
    <Styles.ImageBG>
      <SVG.BgDots
        style={{
          fill: "white",
          color: "white",
          position: "absolute",
          right: "-110px",
          top: "-110px",
        }}
      />
      <SVG.BgDots
        style={{
          fill: "white",
          color: "white",
          position: "absolute",
          bottom: "-110px",
          left: "-110px",
        }}
      />
      <FormFull formRef={setFormRef} onSubmit={() => {}}>
        <Styles.Content>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              paddingBottom: Spacing(4),
            }}
          >
            <SVG.Logo />
          </div>
          <Styles.Title>{texts.title}</Styles.Title>
          <CustomText
            style={{ paddingBottom: Spacing(0.75) }}
            fontFamily={fonts.regular}
            fontSize={1.5}
          >
            {texts.subTitle}
          </CustomText>
          <CustomText
            style={{ paddingBottom: Spacing(0.5) }}
            fontFamily={fonts.light}
            fontSize={1.25}
          >
            {texts.info}
          </CustomText>
          <CustomText
            style={{ paddingBottom: Spacing(3) }}
            textColor="#1A8FB6"
            fontFamily={fonts.regular}
            fontSize={1.25}
          >
            {texts.required}
          </CustomText>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Input
                name="name"
                required={texts.errorMsg}
                label={texts.inputs.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputEmail
                name="email"
                label={texts.inputs.email}
                required={texts.errorMsg}
                validation={validations.isEmailValid}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                name="phone"
                required={texts.errorMsg}
                label={texts.inputs.phone}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                name="nickname"
                required={texts.errorMsg}
                label={texts.inputs.nickname}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputPassword
                name="password"
                label={texts.inputs.password}
                required={texts.errorMsg}
                validation={validations.isPassword}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputPassword
                name="confirmPassword"
                label={texts.inputs.confirmPassword}
                required={texts.errorMsg}
                validation={(value, ffHandler) => {
                  const pass = ffHandler?.getValue("password", false);

                  if (value === pass) {
                    return null;
                  } else {
                    return "As senhas devem ser iguais";
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ImageInput
                mainImg={mainImg}
                errorImg={errorImg}
                setErrorImg={setErrorImg}
                label="ANEXO DOCUMENTO (RG/PASSAPORTE)"
                setMainImg={setMainImg}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SocialNetwork
                setSocialLinks={setSocialLinks}
                socialLinks={socialLinks}
                errorLinks={errorLinks}
                loading={loading}
                label="VINCULAR REDES SOCIAIS"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input
                maxLength={200}
                name="bio"
                required={texts.errorMsg}
                label={texts.inputs.bio}
                multiline
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: Spacing(2),
            }}
          >
            <ButtonText
              fullWidth={false}
              loading={loading}
              feedback={true}
              onClick={() => navigate("/")}
              label={texts.back}
            />
            <ButtonContained
              fullWidth={false}
              loading={loading}
              feedback={true}
              onClick={() => {
                getSubmitData();
              }}
              label={texts.continue}
            />
          </div>

          <CustomText>
            {process.env.REACT_APP_ENV + " v"}
            {process.env.REACT_APP_VERSION}
          </CustomText>
        </Styles.Content>
      </FormFull>
    </Styles.ImageBG>
  );
}

export default Cadastro;
