import Home from "./home/Home";
import Login from "./login/Login";
import EsqueceuSenha from "./esqueceuSenha/EsqueceuSenha";
import Cadastro from "./cadastro/Cadastro";
const pages = {
  Home,
  Login,
  EsqueceuSenha,
  Cadastro,
};
export default pages;
