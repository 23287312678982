import { paths } from "../navigation/navigate";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
const pagesConfig = {
  notLogged: [
    {
      navigationId: 0,
      path: paths.login,
      name: "Login",
    },
    {
      navigationId: 1,
      path: paths.forgetPassword,
      name: "EsqueceuSenha",
    },
    {
      navigationId: 2,
      path: paths.registry,
      name: "Cadastro",
    },
  ],
  logged: [
    {
      dontShow: true,
      name: "pages",
      pages: [
        {
          navigationId: 0,
          path: paths.login,
          name: "Home",
          working: true,
        },
      ],
    },
  ],
};
export default pagesConfig;
