import React, { useContext } from "react";
import Styles from "../styles/Styles";
import Drawer from "@material-ui/core/Drawer";
import { CircularProgress, IconButton, makeStyles } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { CustomText, DefaultLogo } from "../../../components";
import { Colors, fonts, pagesConfig, Spacing } from "../../../config";
import styled from "styled-components";
import { MenuGroupWithPages } from "../components";
import { Close, ExitToApp } from "@material-ui/icons";
import { customModal } from "../../../components/modals/utils";
import { useNavigate } from "react-router-dom";
import { StorageContext } from "../../../contexts/StorageContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawerPaper: {
    borderRight: "none",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));


function Menu({ openMenu, isOpen }) {
  const { logOut } = useContext<any>(StorageContext);
  const navigate = useNavigate();
  const classes = useStyles();

  function handleLogout() {
    customModal.setInfos(
      "Sair",
      ["Sair do sistema e encerrar sua sessão?"],
      {
        label: "Sim, Sair",
        onClick: () => {
          customModal.close();
  
          logOut();
          navigate("/");
        },
      },
      {
        label: "Voltar",
        onClick: () => {
          customModal.close();
        },
      },
      null,
      null
    );
  }

  const drawer = (
    <>
      <Styles.Container>
        <Styles.ScrollContainer>
          <Styles.Header>
            <DefaultLogo white maxWidth={Spacing(10)} />
            <IconButton size="small" onClick={() => openMenu(!isOpen)}>
              <Close />
            </IconButton>
          </Styles.Header>

          {pagesConfig ? (
            pagesConfig?.logged?.map((item, index) => (
              <MenuGroupWithPages
                key={item?.name + index}
                headerName={item?.name}
                item={item.pages}
                firstItem={index === 0}
                pathname={"/geral"}
              />
            ))
          ) : (
            <div
              style={{
                width: "96%",
                marginTop: "10%",
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          )}
          <div>
            <Styles.Page $currentPage={true} exit onClick={handleLogout}>
              <Styles.PageContent exit $currentPage={true}>
                <ExitToApp style={{color: 'white'}} />
                Sair
              </Styles.PageContent>
            </Styles.Page>
            <CustomText
              fontSize={1}
              fontFamily={fonts.light}
              textColor={Colors.white}
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBlock: Spacing(0.5),
                opacity: 0.8,
              }}
            >
              {process.env.REACT_APP_ENV + " v"}
              {process.env.REACT_APP_VERSION}
            </CustomText>
          </div>
        </Styles.ScrollContainer>
        <CustomText
          fontSize={1.5}
          fontFamily={fonts.medium}
          textColor={Colors.white}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBlock: Spacing(3),
          }}
        >
          {process.env.REACT_APP_ENV + " v"}
          {process.env.REACT_APP_VERSION}
        </CustomText>
      </Styles.Container>
    </>
  );

  const MyHidden = styled(Hidden)(() => {
    return {};
  });

  return (
    <nav aria-label="mailbox folders">
      <MyHidden smUp={true} implementation="css">
        <Drawer
          variant="temporary"
          anchor={"left"}
          open={isOpen}
          onClose={() => openMenu(!isOpen)}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </MyHidden>
    </nav>
  );
}

export default Menu;
