import React, { ReactElement } from "react";

import Styles from "../styles";

import { fonts, Spacing, Theme } from "../../../config";

import {
  CircularProgress,
  FormHelperText,
  Grid,
  SvgIconTypeMap,
} from "@material-ui/core";
import OneImage from "./OneImage";
import {
  ButtonContained,
  ButtonText,
  Checkbox,
  CustomText,
  FontStyles,
  Input,
} from "../../../components";
import { customModal } from "../../../components/modals/utils";
import { Facebook, Instagram, LinkedIn, Twitter } from "@material-ui/icons";
import { FormFull } from "form-full";

function TermsModal({ onClick, terms }) {
  const styledParagraph = (text) => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    const italicRegex = /\*(.*?)\*/g;
    const underlineRegex = /__(.*?)__/g;

    const formatText = (input) => {
      let formattedText = input
        .replace(boldRegex, "<strong>$1</strong>")
        .replace(italicRegex, "<em>$1</em>")
        .replace(underlineRegex, "<u>$1</u>");

      return (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            lineHeight: "114%",
          }}
          dangerouslySetInnerHTML={{ __html: formattedText }}
        />
      );
    };

    return formatText(text);
  };

  return (
    <FormFull onSubmit={onClick}>
      <div
        style={{
          padding: Spacing(2),
          paddingInline: Spacing(3),
          width: "100%",
        }}
      >
        <div
          style={{
            backgroundColor: "#ECECEC",
            borderRadius: Spacing(2),
            padding: Spacing(2.25),
            maxHeight: "47vh",
            overflowX: "auto",
          }}
        >
          <p
            style={{
              whiteSpace: "pre-wrap",
              ...FontStyles.medium12,
              padding: 0,
              margin: 0,
            }}
          >
            {styledParagraph(terms)}
          </p>
        </div>
        <Checkbox
          custom
          label="Li e concordo com os Termos de Uso"
          name="accept"
          required="*Campo obrigatório"
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: Spacing(1),
            flexWrap: "wrap",
          }}
        >
          <ButtonText
            onClick={() => {
              customModal.close();
            }}
            fullWidth={false}
          >
            Voltar
          </ButtonText>
          <ButtonContained fullWidth={false} action="submit">
            Sim, Continuar
          </ButtonContained>
        </div>
      </div>
    </FormFull>
  );
}

export default TermsModal;
