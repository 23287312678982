import React from "react";
import {
  FormControlLabel,
  FormControl,
  FormHelperText,
  Checkbox,
  withTheme,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import styled from "styled-components";
import { useFormFull } from "form-full";
const BorderIcon = styled.div(({ theme, selected }) => {
  const { spacing, palette: colors } = theme;

  return {
    width: spacing(2),
    height: spacing(2),
    borderRadius: spacing(0.6),
    border: selected
      ? `2px solid ${colors.text.secondary}`
      : `2px solid ${colors.text.secondary}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
});
const Icon = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;

  return {
    borderRadius: spacing(0.2),
    backgroundColor: colors.text.secondary,
    width: spacing(1),
    height: spacing(1),
  };
});
const CheckboxCustom = styled(Checkbox)(({ theme }) => {
  const { spacing, palette: colors } = theme;

  return {};
});

const FormControlLabelText = styled(FormControlLabel)(({ theme }) => {
  const { spacing, palette: colors } = theme;

  return { "& .MuiTypography-root": { fontSize: "12px", color: "black" } };
});

function CheckboxComponent(props) {
  const { label, required, disableError } = props;

  const { value, error, onBlur, ref, valid, onChange, ffHandler } =
    useFormFull.field(props);

  function getErrorClassname(base, error, valid) {
    const className = base;
    if (error) return `${className} invalid`;
    if (valid) return `${className} valid`;
    return className;
  }

  return (
    <FormControl
      className={getErrorClassname("form-control", error, valid)}
      fullWidth
      error={Boolean(error)}
    >
      <FormControlLabelText
        control={
          props.custom ? (
            <CheckboxCustom
              checked={value}
              inputRef={ref}
              icon={<BorderIcon />}
              checkedIcon={
                <BorderIcon selected>
                  <Icon />
                </BorderIcon>
              }
            />
          ) : (
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              checked={value}
              inputRef={ref}
            />
          )
        }
        onChange={(event, checked) => {
          onChange(event, checked);
        }}
        label={label}
        labelPlacement="end"
      />
      {disableError || !error ? null : (
        <FormHelperText>{error ? error : " "}</FormHelperText>
      )}
    </FormControl>
  );
}

export default withTheme(CheckboxComponent);
