import React from "react";
import { CustomText } from "../../components";

import { fonts } from "../../config";

import Styles from "./styles";

function Home() {
  const [isMounted, setMount] = React.useState<boolean>(false);

  const Mount = React.useCallback(() => {}, []);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  return (
    <>
      <Styles.Container>
        <CustomText fontSize={2.55} fontFamily={fonts.bold}>
          Home
        </CustomText>
      </Styles.Container>
    </>
  );
}

export default Home;
