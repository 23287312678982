import React from "react";

import Styles from "../styles";

import { Spacing, Theme } from "../../../config";

import { FormHelperText } from "@material-ui/core";
import OneImage from "./OneImage";

function ImageInput({ label, setMainImg, mainImg, errorImg, setErrorImg }) {
  return (
    <>
      <Styles.LabelImage $withError={Boolean(errorImg)}>
        {label}
      </Styles.LabelImage>
      <OneImage
        dataUser={mainImg}
        updateImage={(img) => {
          setMainImg(img);
          setErrorImg(null);
        }}
      />
      <FormHelperText
        style={{ minHeight: Spacing(2.5) }}
        error={Boolean(errorImg)}
      >
        {errorImg}
      </FormHelperText>
    </>
  );
}

export default ImageInput;
