import React, { useEffect } from "react";

import { CustomText, Tooltip } from "../../../components";

import { CircularProgress, makeStyles } from "@material-ui/core";

import Styles from "../styles";

import { fonts, Spacing, Theme } from "../../../config";
import { Close } from "@material-ui/icons";
interface PageProps {
  updateImage: (image: any) => void;
  loading?: boolean;
  error?: boolean;
  id?: number;
  customImg?: string;
  dataUser?: any;
  readOnly?: boolean;
}
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    margin: 0,
  },
  textContainer: {
    flex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex", // Adiciona display flex para evitar que o texto saia do contêiner
    alignItems: "center", // Centraliza o texto verticalmente
  },
  currentText: {
    flex: 1, // Permite que o texto ocupe todo o espaço do contêiner
    whiteSpace: "nowrap", // Evita que o texto quebre linha
    overflow: "hidden", // Evita que o texto derrame para fora do contêiner
    textOverflow: "ellipsis", // Adiciona reticências quando o texto transborda
    animation: "$slide 0.5s ease-in-out", // Adiciona a animação de deslizamento
  },
  "@keyframes slide": {
    "0%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(-100%)", // Modificamos para deslizar para a esquerda
    },
  },
  slideOutRight: {
    animationName: "$slide",
  },
  slideOutLeft: {
    animationDirection: "reverse", // Inverte a direção da animação
    animationName: "$slide",
  },
}));

function OneImage({
  updateImage,
  loading,
  error,
  id,
  dataUser,
  readOnly,
}: PageProps) {
  const classes = useStyles();
  const [imgSrc, setImgSrc] = React.useState<any>(dataUser ? dataUser : null);

  useEffect(() => {
    if (dataUser?.src) {
      const imageUrl = dataUser.src;
      return () => {
        URL.revokeObjectURL(imageUrl);
      };
    }
  }, [dataUser?.src]);

  const savePcPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImgSrc({
          src: e?.target?.result as string,
          name: event.target.files?.[0].name,
          file: file,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    updateImage(imgSrc);
  }, [imgSrc]);

  return (
    <>
      <Styles.InputImageContainer>
        {imgSrc !== null ? (
          <Styles.InputImageModal>
            {!readOnly && (
              <Styles.CustomButton
                noMargin
                $width={2}
                $padding={0}
                bg="transparent"
                $size={1.5}
                onClick={() => {
                  setImgSrc(null);
                  const inputData = document.getElementById(
                    id ? "fileInputPhoto-" + id : "fileInputPhoto"
                  ) as any;
                  if (inputData) {
                    inputData.value = null;
                  }
                }}
              >
                <Close style={{ display: "flex" }} />
              </Styles.CustomButton>
            )}
            <span
              style={{
                margin: 0,
                padding: 0,
                display: "flex",
                justifyContent: "flex-start",
                width: "100vw",
                overflow: "hidden",
                paddingRight: "8px",
              }}
              onClick={() => {
                loading !== true &&
                  !readOnly &&
                  document
                    .getElementById(
                      id ? "fileInputPhoto-" + id : "fileInputPhoto"
                    )
                    ?.click();
              }}
            >
              <Tooltip
                title={
                  <CustomText
                    style={{
                      textDecorationLine: "underline",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      [Theme.Light.breakpoints.down("sm")]: {
                        fontSize: Spacing(1.5),
                      },
                    }}
                    textColor="black"
                    fontSize={1.75}
                    fontFamily={fonts.medium}
                  >
                    {imgSrc?.name}
                  </CustomText>
                }
                tip={
                  <>
                    {dataUser?.src ? (
                      <div style={{}}>
                        <img
                          style={{
                            width: "auto",
                            height: "200px",
                            borderRadius: 8,
                          }}
                          src={dataUser.src}
                          alt={dataUser.name}
                        />
                      </div>
                    ) : (
                      <CustomText
                        style={{
                          textDecorationLine: "underline",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          [Theme.Light.breakpoints.down("sm")]: {
                            fontSize: Spacing(1.5),
                          },
                        }}
                        textColor="black"
                        fontSize={1.75}
                        fontFamily={fonts.medium}
                      >
                        Sem imagem
                      </CustomText>
                    )}
                  </>
                }
              ></Tooltip>
            </span>
          </Styles.InputImageModal>
        ) : (
          <Styles.InputImageModal
            onClick={() => {
              loading !== true &&
                !readOnly &&
                document
                  .getElementById(
                    id ? "fileInputPhoto-" + id : "fileInputPhoto"
                  )
                  ?.click();
            }}
          >
            {loading ? (
              <CircularProgress size={10} />
            ) : (
              <CustomText
                style={{
                  textDecorationLine: "underline",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  [Theme.Light.breakpoints.down("sm")]: {
                    fontSize: Spacing(1.5),
                  },
                }}
                textColor="black"
                fontSize={1.75}
                fontFamily={fonts.medium}
              >
                {readOnly ? "Sem Imagem" : "Anexar Imagem"}
              </CustomText>
            )}
          </Styles.InputImageModal>
        )}
        <input
          id={id ? "fileInputPhoto-" + id : "fileInputPhoto"}
          type="file"
          accept="image/jpeg, image/png"
          onChange={savePcPhoto}
          style={{ display: "none" }}
        />
      </Styles.InputImageContainer>
    </>
  );
}

export default OneImage;
