import { ReactComponent as Mug } from "../assets/svg/mug-hot-alt.svg";
import { ReactComponent as Logo } from "../assets/svg/logo.svg";
import { ReactComponent as BgDots } from "../assets/svg/dotBg.svg";
const SVG = {
  Mug,
  BgDots,
  Logo,
};

export default SVG;
