import React, { ReactElement } from "react";

import Styles from "../styles";

import { fonts, Spacing, Theme } from "../../../config";

import {
  CircularProgress,
  FormHelperText,
  Grid,
  SvgIconTypeMap,
} from "@material-ui/core";
import OneImage from "./OneImage";
import {
  ButtonContained,
  ButtonText,
  CustomText,
  Input,
} from "../../../components";
import { customModal } from "../../../components/modals/utils";
import { Facebook, Instagram, LinkedIn, Twitter } from "@material-ui/icons";
import { FormFull } from "form-full";

function SocialModal({ links, setLinks }) {
  const [formRef, setFormRef] = React.useState<any>();
  const inputSocial: {
    label: string;
    icon: ReactElement<any, any>;
    key: string;
  }[] = [
    {
      label: "instagram",
      icon: <Instagram style={{ color: "#1A8FB6" }} />,
      key: "INSTAGRAM",
    },
    {
      label: "facebook",
      key: "FACEBOOK",
      icon: <Facebook style={{ color: "#1A8FB6" }} />,
    },
    {
      label: "twitter",
      key: "TWITTER",
      icon: <Twitter style={{ color: "#1A8FB6" }} />,
    },
    {
      label: "linkedin",
      key: "LINKEDIN",
      icon: <LinkedIn style={{ color: "#1A8FB6" }} />,
    },
  ];

  const sendLinks = async () => {
    if (formRef) {
      let dataForm = await formRef?.testErrorsAndReturnData();
      if (dataForm.hasError !== true) {
        const linkList: {
          label: string;
          icon: ReactElement<any, any>;
          value: string;
          key: string;
        }[] = [];

        for (let index = 0; index < inputSocial.length; index++) {
          const element = inputSocial[index];
          if (
            Boolean(dataForm?.data[element?.label]) &&
            dataForm?.data[element?.label] !== ""
          ) {
            linkList.push({
              ...inputSocial[index],
              value: dataForm?.data[element?.label],
            });
          }
        }

        setLinks(linkList);
        customModal.close();
      }
    }
  };

  return (
    <FormFull formRef={setFormRef} onSubmit={() => {}}>
      <div
        style={{
          padding: Spacing(2),
          paddingInline: Spacing(3),
          width: "100%",
        }}
      >
        <Grid container spacing={1}>
          {inputSocial?.map((item) => {
            const defValue = links?.find((i) => i?.label === item?.label);
            return (
              <Grid item xs={12}>
                <Input
                  defaultValue={defValue && defValue?.value}
                  adornment={item?.icon}
                  name={item?.label}
                  label={item?.label}
                />
              </Grid>
            );
          })}

          <Grid item xs={12}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: Spacing(1),
              }}
            >
              <ButtonText
                onClick={() => {
                  customModal.close();
                }}
                fullWidth={false}
              >
                Voltar
              </ButtonText>
              <ButtonContained fullWidth={false} onClick={sendLinks}>
                Salvar
              </ButtonContained>
            </div>
          </Grid>
        </Grid>
      </div>
    </FormFull>
  );
}

export default SocialModal;
