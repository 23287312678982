import React from "react";

import Styles from "../styles";

import { fonts, Spacing, Theme } from "../../../config";

import { CircularProgress, FormHelperText } from "@material-ui/core";
import OneImage from "./OneImage";
import { CustomText } from "../../../components";
import { customModal } from "../../../components/modals/utils";
import SocialModal from "./SocialModal";
import { AlternateEmail } from "@material-ui/icons";

function SocialNetwork({
  label,
  setSocialLinks,
  socialLinks,
  errorLinks,
  loading,
}) {
  const getLinks = () => {
    if (socialLinks?.length > 0) {
      return socialLinks.map((item) => (
        <div
          style={{
            paddingInline: Spacing(1.25),
            paddingBlock: Spacing(0.625),
            backgroundColor: "#1A8FB6",
            borderRadius: "99px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CustomText
            fontFamily={fonts.medium}
            fontSize={1.25}
            textColor="white"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: Spacing(0.75),
            }}
          >
            {item?.icon}@{item?.value}
          </CustomText>
        </div>
      ));
    } else {
      return "Vincular Redes Sociais";
    }
  };

  const openLinksModal = () => {
    customModal.setInfos(
      "Vincular Redes Sociais",
      [],
      null,
      null,
      <SocialModal links={socialLinks} setLinks={setSocialLinks} />,
      false,
      false
    );
  };

  return (
    <>
      <Styles.LabelImage $withError={Boolean(errorLinks)}>
        {label}
      </Styles.LabelImage>
      <>
        <Styles.InputImageContainer>
          <Styles.InputImageModal>
            <span
              style={{
                margin: 0,
                padding: 0,
                display: "flex",

                width: "100vw",
                overflow: "hidden",
                paddingRight: "8px",
                flexWrap: "wrap",

                textAlign: "center",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={() => {
                !loading && openLinksModal();
              }}
            >
              <CustomText
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",

                  gap: Spacing(0.5),
                  [Theme.Light.breakpoints.down("sm")]: {
                    fontSize: Spacing(1.5),
                  },
                }}
                textColor="#00000054"
                fontSize={1.75}
                fontFamily={fonts.medium}
              >
                {getLinks()}
              </CustomText>
              <AlternateEmail
                style={{ color: "#1A8FB6", fontSize: Spacing(2) }}
              />
            </span>
          </Styles.InputImageModal>
        </Styles.InputImageContainer>
      </>
      <FormHelperText
        style={{ minHeight: Spacing(2.5) }}
        error={Boolean(errorLinks)}
      >
        {errorLinks}
      </FormHelperText>
    </>
  );
}

export default SocialNetwork;
